jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$(".fancybox-thumb").fancybox({
		prevEffect: 'none',
		nextEffect: 'none',
		helpers: {
			title: {
				type: 'outside'
			},
			thumbs: {
				width: 150,
				height: 150
			}
		}
	});

	//kapcsolat oldalon a téképek
	$('.map').each(function () {
		var id = $(this).attr('id');
		long = $(this).data('long');
		lat = $(this).data('lat');
		initialize(id, long, lat);

		$(this).append("<div class='telepules-cim'>" + $(this).data('cim') + "</div>");
	});

	$('.telepules-lista').on('click', '.modal-nyito', function () {
		var link = $(this).data('link');
		var src = $(this).data('src');
		var nev = $(this).data('nev');
		$('#kapcsolat-form').attr('action', link);
		$('#kapcsolat-modal-kep').attr('src', src);
		$('#kapcsolat-modal-nev').text(nev);
	});

	$('.munkafolyamat-lista').on('click', '.munkafolyamat-elem', function () {

		var $this = $(this);
		var keplayer = $this.data('keplayer');

		$('.munkafolyamat-elem').removeClass('active');
		$this.addClass('active');
		kepnyitas(keplayer);
	});

	$('.munkafolyamat-kep').on('click', '.kep-layer:not(.open)', function () {

		var $this = $(this);
		var keplayer = $this.data('keplayer');

		$('.munkafolyamat-elem').removeClass('active');
		$('.munkafolyamat-elem-' + keplayer).addClass('active');

		kepnyitas(keplayer);
	});

	$('.munkafolyamat-kep').on('click', '.kep-layer.open', function () {

		var $this = $(this);
		var keplayer = $this.data('keplayer');

		$('.munkafolyamat-elem').removeClass('active');
		$('.open').addClass('opening').removeClass('open');
		setTimeout(function () {
			$('.kep-layer').removeClass('opening');
		}, 700);
	});
});

function initialize(id, long, lat) {
	// Create a map object, and include the MapTypeId to add
	// to the map type control.
	var mapOptions = {
		zoom: 16,
		scrollwheel: false,
		center: new google.maps.LatLng(long, lat),
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
		}
	};
	var map = new google.maps.Map(document.getElementById(id), mapOptions);

	//Associate the styled map with the MapTypeId and set it to display.

	var beachMarker = new google.maps.Marker({
		position: {lat: long, lng: lat},
		map: map
		// icon: "/images/marker.png"
	});
}

var recaptcha = function () {
	$('.g-recaptcha').each(function () {
		var $this = $(this);
		grecaptcha.render($this[0], $this.data());
	})
};

function onSubmitKapcsolat(token) {
	// console.log('itt vagy');
	document.getElementById("kapcsolat-form").submit();
}

$(document).on('click', 'a.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	morepages: function (resp, $elem) {
		$($elem.data('target')).append(resp);
		$elem.parent('.hir-lista-elem').remove();
	},
	morereferencia: function (resp, $elem) {
		$($elem.data('target')).append(resp);
		$elem.parent('.referencia-kep').remove();
	},
};

function kepnyitas(keplayer){
	if ($('.kep-layer').hasClass('open')) {

		$('.open').addClass('opening').removeClass('open');

		setTimeout(function () {
			$('.kep-layer').removeClass('opening');
			$('.kep-layer-' + keplayer).addClass('open');
		}, 700);
	} else {
		$('.kep-layer-' + keplayer).addClass('open');
	}
}